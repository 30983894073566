*,
::after,
::before {
    box-sizing: border-box;
}

@-ms-viewport {
    width: device-width;
}

p,
ul {
    margin-top: 0;
    margin-bottom: 1rem;
}

b,
strong {
    font-weight: bolder;
}

a {
    text-decoration: none;
    -webkit-text-decoration-skip: objects;
}

img {
    vertical-align: middle;
    border-style: none;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

h2,
h3,
h4,
h5 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.2;
    color: inherit;
}

h2 {
    font-size: 2rem;
}

h3 {
    font-size: 1.75rem;
}

h4 {
    font-size: 1.5rem
}

h5 {
    font-size: 1.25rem;
}

.small {
    font-size: 80%;
    font-weight: 400;
}

.container {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}

.hide-on-mob {
    display: none;
}

@media (min-width: 768px) {
    .mob-only {
        display: none;
    }

    .hide-on-mob {
        display: block;
    }
}

.flex-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.tiny-vertical-padding {
    padding-top: .25rem;
}

.tiny-horizontal-padding {
    padding-right: .25rem;
}

.tiny-vertical-padding {
    padding-bottom: .25rem;
}

.tiny-horizontal-padding {
    padding-left: .25rem;
}

.text-center {
    text-align: center;
}

.font-italic {
    font-style: italic;
}

@font-face {
    font-family: Gotham;
    src: url(../fonts/gotham/gotham-light.ttf);
}

@font-face {
    font-family: GothamBold;
    src: url(../fonts/gotham/gotham-medium.ttf);
}

@font-face {
    font-family: Intro;
    src: url(../fonts/intro/intro-regular.otf);
}

@font-face {
    font-family: IntroBold;
    src: url(../fonts/intro/intro-bold.otf);
}

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.aspect-fix {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000;
    opacity: 1;
    z-index: 7;
    display: none;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    font-size: .82rem;
    font-family: "IntroBold", 'Arial', 'Helvetica', 'sans-serif';
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 3rem;
    padding-top: 3rem;
}

section.main.top {
    padding-top: 47px;
}

section.main.top .intro-top {
    position: relative;
}

section.intro .branding {
    text-align: center;
    margin-top: 4rem;
}

section.intro .intro-bottom {
    position: relative;
}

section.intro .text-cont .heading {
    font-family: "IntroBold", 'Arial', 'Helvetica', 'sans-serif';
    text-transform: uppercase;
}

section.intro .text-cont a.red {
    color: #dd1515;
    font-family: inherit;
    font-size: inherit;
    text-transform: unset;
}

section.intro .text-cont a:hover {
    text-decoration: none;
}

section.intro .text-cont.extra-info-wrap {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 3.5rem;
}

section.intro .text-cont.extra-info-wrap p {
    line-height: 1.3;
    font-size: 1.2em;
}

.immerse,
div#games .heading {
    font-family: "IntroBold", 'Arial', 'Helvetica', 'sans-serif';
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 1.1;
}

div#games .none-quote-heading {
    font-family: "IntroBold", 'Arial', 'Helvetica', 'sans-serif';
    text-transform: uppercase;
    font-size: 1.6rem;
    line-height: 1.1;
    padding-bottom: .5em;
    margin: 0;
}


/* Game cards section */

section.game {
    cursor: pointer;
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;
    height: calc(100vh - 40px);
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
}

section.game img.poster {
    width: 100%;
    height: auto;
    margin: 0;
}

@media screen and (min-width: 768px) {
    section.game img.poster {
        height: 39.79rem;
        object-fit: cover;
    }
    
}

section.game .intro {
    font-weight: 600;
    font-size: .9em;
    text-transform: uppercase;
    margin-top: 0;
}

section.game .card-tag-wrap {
    position: absolute;
    display: flex;
    top: 0;
    width: 100%;
}

section.game .card-tag {
    text-transform: uppercase;
    background: #e9e9e9;
    color: #000;
    line-height: 1;
    display: inline-block;
    margin: .3rem auto 0;
    padding: .15rem .5rem;
    font-weight: 700;
}

section.game .text-cont {
    position: absolute;
    width: 100%;
    bottom: 1rem;
    left: 0;
    padding: 0 1rem;
}

section.game .text-cont .heading {
    font-family: "IntroBold", 'Arial', 'Helvetica', 'sans-serif';
    text-transform: uppercase;
    font-size: 1.1rem;
    margin: 0;
    line-height: 1.1;
}

section.game .text-cont .heading:after,
section.game .text-cont .heading:before {
    content: "";
    width: 12px;
    height: 12px;
    background-image: url("../images/quote.png");
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    vertical-align: top;
    margin-top: -1px;
    margin-right: 4px;
}

section.game .text-cont .heading:after {
    transform: scale(-1, 1);
    margin-right: 0;
    margin-left: 4px;
}

section.game .text-cont span.source {
    color: #95d0be;
    font-size: .6rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: -2px 0 1rem;
    display: block;
}

section.game .text-cont p:not(.card-bottom-line) {
    text-transform: uppercase;
    line-height: 1.3;
    margin: 0;
    font-size: .82rem;
}

section.game .text-cont p.card-bottom-line {
    font-size: 1rem !important;
    margin: 0;
    letter-spacing: unset;
    line-height: unset;
}

section.game .text-cont p.card-bottom-line {
    font-size: .9rem !important;
}

section.game .text-cont p.card-bottom-line>span:first-of-type {
    display: inline-block;
    margin-bottom: .5rem;
}

section.game .text-cont p.card-bottom-line .card-time {
    display: inline-block;
    min-height: 33px;
}

section.game .text-cont p.card-bottom-line {
    position: absolute;
    bottom: -.5rem;
    width: calc(100% - 2rem);
}

section.game .text-cont {
    padding-bottom: 1.75rem;
}

section.game .text-cont p.small {
    font-size: .8em !important;
    font-weight: 600;
}


.divider-grad {
    height: 90px;
    margin: -40px 0;
    z-index: 2;
    position: relative;
    pointer-events: none;
    background: -moz-linear-gradient(top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 33%,
            rgba(0, 0, 0, 1) 47%,
            rgba(0, 0, 0, 1) 55%,
            rgba(0, 0, 0, 1) 56%,
            rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient(top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 33%,
            rgba(0, 0, 0, 1) 47%,
            rgba(0, 0, 0, 1) 55%,
            rgba(0, 0, 0, 1) 56%,
            rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 33%,
            rgba(0, 0, 0, 1) 47%,
            rgba(0, 0, 0, 1) 55%,
            rgba(0, 0, 0, 1) 56%,
            rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#00000000', GradientType=0);
    /* IE6-9 */
}

@media (min-width: 768px) {
    .divider-grad {
        height: 280px;
        margin: -140px 0;
    }
}


/*
      Home Logo Animation
   */

#home-logo {
    width: 100vw;
    max-width: 220px;
    position: relative;
    margin: 0 auto;
    overflow: visible;
}

/* Corporate page */

.corp-button {
    display: block;
    padding: 1em;
    height: unset;
    background-image: none;
    cursor: default;
    position: relative;
    color: white !important;
    background: rgba(221, 21, 21, 0.8);
    text-decoration: none;
    font-family: "RajdhaniRegular", sans-serif;
    font-weight: bold;
    font-size: 18px;
    display: inline-block;
    line-height: 1.2;
    letter-spacing: 0.3px;
    z-index: 1;
    min-width: 4.9em;
}

.corp-button strong {
    display: block;
}

section#companies .companies-logos-wrap {
    max-width: 1200px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

section#companies .companies-logo-row {
    max-width: 100%;
    width: 100%;
    flex: 1 1 100%;
    align-self: center;
}

section#companies .companies-logo-row section#testimonials span.nowrap {
    white-space: nowrap;
}

section#reviewWidgets {
    padding: 4rem 0;
}

@media (min-width: 768px) {
    section#reviewWidgets {
        padding: 4rem 0 7rem;
    }

    section#companies,
    section#testimonials {
        padding-top: 4rem;
    }
}



/*
  Screen size alert helpers
   */

@media (max-width: 340px) and (max-height: 380px) {
    .aspect-fix {
        display: flex;
    }
}

@media (min-width: 341px) and (max-height: 400px) {
    .aspect-fix {
        display: flex;
    }
}

@media (min-width: 768px) and (max-height: 460px) {
    .aspect-fix {
        display: flex;
    }
}

@media (min-width: 992px) and (max-height: 500px) {
    .aspect-fix {
        display: flex;
    }
}

/* TODO Octophin max-height was 600. Reduced to 550 to resolve issue with macbook air */
@media (min-width: 1200px) and (max-height: 550px) {
    .aspect-fix {
        display: flex;
    }
}

/* TODO Octophin max-height was 670. Reduced to 600 to resolve issue with macbook air */
@media (min-width: 1500px) and (max-height: 600px) {
    .aspect-fix {
        display: flex;
    }
}

/*
  General Responsiveness
   */

@media (min-width: 576px) {
    section.game .intro {
        margin-top: 2rem;
        font-size: 1em;
    }

    section#companies .companies-logo-row {
        flex: 0 1 600px;
    }
}

@media (min-width: 768px) {
    #home-logo {
        max-width: 340px;
    }

    section.intro .branding {
        margin-top: 7rem;
    }

    section.intro .text-cont.extra-info-wrap {
        max-width: 80%;
    }

    section.intro .branding {
        margin-top: 8rem;
    }

    #games>.heading {
        letter-spacing: 1px;
        font-size: .9rem;
        text-align: center;
        display: block;
        margin: 0;
        color: #00060d;
    }

    section.game {
        height: auto;
        min-height: auto;
        background-image: none !important;
        width: auto;
        display: inline-block;
        width: 400px;
        margin: 1rem;
        background-position: center top;
        position: relative;
    }

    section.game .intro {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    section.game .text-cont {
        bottom: 2rem;
    }

    section#companies .companies-logo-row {
        flex: 0 0 50%;
    }
}

@media (min-width: 992px) {
    section.main.top {
        position: relative;
    }

    section.intro .branding {
        margin-top: 8rem;
    }

    #games {
        min-height: calc(100vh - 50px);
        padding-bottom: 2rem;
    }
}

@media (min-width: 1200px) {
    #home-logo {
        max-width: 360px;
    }

    section.intro .branding {
        margin-top: 9rem;
    }
}

@media (min-width: 1500px) {
    #home-logo {
        max-width: 390px;
    }

    section.intro .branding {
        margin-top: 9rem;
    }
}

#games {
    width: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    height: auto;
    background-image: url("../images/home-background-landscape.jpg");
    padding-top: 5rem;
    padding-bottom: 3rem;
    margin: 0;
    overflow: hidden;
    position: relative;
}

div#games .heading.epicvoice {
    margin-bottom: .75rem;
}

section.game {
    height: auto;
    min-height: auto;
    background-image: none !important;
    width: auto;
    display: inline-block;
    width: 400px;
    margin: 1rem;
    background-position: center top;
    position: relative;
}

@media screen and (max-width: 768px) {
    section.game .text-cont {
        bottom: 4em;
    }

    section.game .intro {
        font-weight: 600;
        font-weight: 600;
        text-transform: uppercase;
        margin-top: 0;
        position: absolute;
        top: 1.5em;
        width: 100%;
    }

    section.game .intro,
    section.game .small {
        font-size: 1.5em;
    }

    section.game h4.heading-no-quote.epicvoice {
        font-size: 3.5em;
    }

    section.game {
        height: auto;
        min-height: auto;
        background-image: none !important;
        width: auto;
        display: inline-block;
        width: 100%;
        margin: 0;
        background-position: center top;
        position: relative;
    }
}

h4.heading-no-quote.epicvoice {
    font-size: 2.5em;
}

.heading-no-quote {
    font-size: 2.5em;
    line-height: 1.1;
    font-weight: 700;
    text-transform: uppercase;
    top: 55px;
    position: absolute;
    text-align: center;
    justify-content: center;
    align-items: center;
    width: 100%;
    display: flex;
    left: 0;
}

.game.overlay img {
    filter: brightness(50%);
}

.game {
    position: relative;
}

@media screen and (max-width: 680px) {

    section.game .intro,
    section.game .small {
        font-size: 1.1em;
        top: 1.2em;
    }

    section.game .text-cont {
        bottom: 2em;
    }

    section.game .card-tag-wrap+.intro {
        margin-top: .75rem;
    }
}

@media screen and (max-width: 480px) {
    section.game h4.heading-no-quote.epicvoice {
        font-size: 2.5em;
    }
}

#games::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    content: " ";
    width: 100%;
    pointer-events: none;
}

#games h5 {
    color: #fff !important;
    z-index: 5;
    position: relative;
    font-size: 2em !important;
}

#games p, 
#games span, 
#games li, 
#games .body-text { /* Add other selectors as needed */
    position: relative;
}


/* WP6 preparation  */


.intro-bottom {
    padding-top: 4rem;
    padding-bottom: 2rem;
}

.height-100 {
    height: 100%;
}


/* Video/Image + bulletpoints layout (including Home page "how it works" section) */

.video-image-bullets-explainer-section {
    width: 100%;
    margin: auto;
    position: relative;
}

.video-image-bullets-explainer-section-inner,
.post-hero-section__inner {
    max-width: 100%;
    margin: auto;
    width: 1400px;
    position: relative;
    z-index: 2;
}

.experience-image {
    margin: 2em 0;
    display: flex;
    justify-content: center;
    video, img{
        margin: 0;
        max-width: 100%;
    }
}

.video-image-bullets-explainer {
    display: flex;
    padding-bottom: 1.3125em;
    align-items: flex-start;

    .video, .video-image-bullets-explainer-bullets, .experience-image {
        width: 50%;
    }

    .video{
        margin: 2em 0;
        video{
            margin: 0;
            max-width: 100%;
        }
    }

    .more-about-how-it-works {
        width: 100%;
        justify-content: right;
        display: flex;
    }

    .more-about-event {
        width: 100%;
        justify-content: left;
        display: flex;
    }

    .more-about-how-it-works,
    .more-about-event {
        p {
            font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
        }
    }

    .video-image-bullets-explainer-bullets {
        padding: 0 5em;
        flex-wrap: wrap;

        ul {
            padding: 0;
        }

        li {
            max-width: 800px;
            margin-bottom: 1.5em;
            padding: 0px;
            font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
            line-height: 1.5;
            list-style: none;
            display: flex;
            p{
                line-height: inherit;
                font-size: inherit;
                letter-spacing: inherit;
                margin: 0;
            }               
        }

        li::before {
            content: url(../images/icons/bullet-circle--red-big.svg);
            left: -1em;
            position: relative;
            top: 0.1em;
        }

    }

    .video-image-bullets-explainer-bullets--grey li::before {
        content: url(../images/icons/bullet-circle--grey-big.svg);
    }
}

.video-image-bullets-explainer-bullets--grey {
    @extend .video-image-bullets-explainer-bullets;
}


@media (max-width: 1400px){

    .video-image-bullets-explainer {

        .video-image-bullets-explainer-bullets{
            padding: 0 3em;

            ul {
                

                li {
                    margin-bottom: 1em;
                    line-height: 1.25;
                    margin-left: 1em;
                }
            }
        }

        .more-about-how-it-works {
            margin-top: 1em;
            width: 100%;
            justify-content: center;
            display: flex;
        }
    }
}


@media (max-width: 992px){

    .video-image-bullets-explainer, .video-large-centre {
        display:block;

        .video, .video-image-bullets-explainer-bullets, .experience-image {
            width: 100%;
        }

        .video-image-bullets-explainer-bullets ul{
            margin-left: auto;
            margin-right: auto;
            max-width: 800px;
        }
    }  
}

@media (max-width: 576px) {
    .video-image-bullets-explainer .video-image-bullets-explainer-bullets {
        padding: 0 1em;
    }
}
  


// Hide video and show fallback (on devices where poster doesn't work)

.home-intro[data-has-video] {
    background-position: -10000px;
    background-repeat: no-repeat;
}

.home-intro:not([data-has-video]) {
    background-position: 0;

    video {
        display: none;
    }
}
