/* Inhertited  */

@font-face {
    font-family: Solomon;
    src: url(../fonts/solomon/solomon-normal.otf);
}

@font-face {
    font-family: SolomonBold;
    src: url(../fonts/solomon/solomon-bold.otf);
}

@font-face {
    font-family: SolomonBlack;
    src: url(../fonts/solomon/solomon-black.otf);
}

@font-face {
    font-family: Arquitecta;
    src: url(../fonts/arquitecta/arquitecta.otf);
}

@font-face {
    font-family: ArquitectaBold;
    src: url(../fonts/arquitecta/ArquitectaBold.otf);
}

@font-face {
    font-family: ArquitectaBlack;
    src: url(../fonts/arquitecta/ArquitectaBlack.otf);
}

* {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

*::selection {
    background: #b3c1d8;
    /* WebKit or Blink Browsers */
}

*::-moz-selection {
    background: #b3c1d8;
    /* Gecko Browsers */
}

.divider-grad {
    height: 90px;
    margin: -40px 0;
    z-index: 2;
    position: relative;
    pointer-events: none;
    background: -moz-linear-gradient( top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 47%, rgba(0, 0, 0, 1) 55%, rgba(0, 0, 0, 1) 56%, rgba(0, 0, 0, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient( top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 47%, rgba(0, 0, 0, 1) 55%, rgba(0, 0, 0, 1) 56%, rgba(0, 0, 0, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient( to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1) 46%, rgba(0, 0, 0, 1) 47%, rgba(0, 0, 0, 1) 55%, rgba(0, 0, 0, 1) 56%, rgba(0, 0, 0, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

section.main {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

section.main .overlay {
    position: relative;
}

section.main.top .text-cont {
    margin-top: -2rem;
}

section.main .text-cont {
    z-index: 3;
}

section.main.fourth .text-cont {
    width: 100%;
}

section.main .text-cont .divider {
    display: block;
    margin: 1.5rem;
}

section.main .text-cont .divider:after {
    content: "";
    width: 140px;
    height: 1px;
    background: #73c7b5;
    transform: rotate(8deg);
    display: block;
    margin: 0 auto;
}

section.main .text-cont .divider.left:after {
    content: "";
    width: 140px;
    height: 1px;
    background: #73c7b5;
    transform: rotate(-8deg);
    display: block;
    margin: 0 auto;
}

section.main .text-cont p {
    display: block;
    margin: 0 auto;
    text-shadow: 0 0 16px rgba(0, 0, 0, 1);
    line-height: 1.3;
}

section.main.second .text-cont p {
    max-width: 390px;
}

section.main .text-cont p.meta {
    text-transform: none;
    line-height: 1.2;
}

section.main .text-cont p.meta span {
    font-size: 0.9rem;
}

section.main .text-cont p span.fully-flexible img {
    width: 1rem;
    margin-right: 0.25rem;
}

section.main .text-cont p span.fully-flexible {
    text-transform: none;
}


/* New things */

#book.anchor,
#gather-your-friends.anchor {
    top: -15px;
}

#calendar-wrapper-anchor.anchor {
    top: 9px;
}

@media screen and (min-width: 600px) {
    #calendar-wrapper-anchor.anchor {
        top: -36px;
    }
}

@media screen and (min-width: 768px) {
    #book.anchor,
    #gather-your-friends.anchor {
        top: -15px;
    }
    #calendar-wrapper-anchor.anchor {
        top: -35px;
    }
}

@media screen and (min-width: 874px) {
    #book.anchor,
    #gather-your-friends.anchor {
        top: -25px;
    }
    #calendar-wrapper-anchor.anchor {
        top: -65px;
    }
}


/* end new things */

section.main .text-cont .stars {
    font-size: 1.4rem;
}

section.main .text-cont .stars i.fa {
    margin: 0.1rem 0.2rem;
}

@media (max-height: 540px) {
    section.main.top .text-cont {
        margin-top: 0rem !important;
    }
}

@media (min-width: 340px) {
    section.main .text-cont p {
        /* font-size: 0.9rem; */
        width: 80%;
    }
    section.main .text-cont p.meta {
        width: 100%;
    }
}

@media (min-width: 576px) {
    section.main .text-cont p {
        /* font-size: 1rem; */
        width: auto;
    }
    section.main .text-cont p.meta span,
    section.main .text-cont p span.small {
        font-size: 1rem;
    }
    section.main .text-cont .divider:after {
        width: 180px;
        margin: 2rem auto 2.2rem;
    }
    section.main .text-cont .divider.left:after {
        width: 180px;
        margin: 2rem auto 2.2rem;
    }
}

@media (min-width: 768px) {
    .divider-grad {
        height: 130px;
        margin: -60px 0;
    }
}

@media (min-width: 992px) {
    section.main .text-cont .narrow {
        max-width: 520px !important;
    }
    section.main.second .text-cont p {
        max-width: 500px;
    }
}

@media (min-width: 1200px) and (min-height: 812px) {
    section.main .text-cont {
        max-width: 60%;
    }
    section.main.top .text-cont {
        max-width: none;
    }
    section.main .text-cont p.meta span,
    section.main .text-cont p span.small {
        font-size: 1.2rem;
    }
}


/* Reviews section */

section#reviews .heading.epicvoice {
    z-index: 5;
    position: relative;
}


/* Important? */

section.main .text-cont {
    max-width: 80%;
}

* {
    -moz-osx-font-smoothing: grayscale;
}


/* Layouts? */

.text-cont .branding {
    margin: auto;
}

.vertical-divider-padding-top {
    padding-top: 3rem;
}

.vertical-divider-padding-bottom {
    padding-bottom: 3rem;
}

section.landing-page>section.black-base {
    z-index: 3;
    position: relative;
}

section.book-now {
    padding-top: 2rem;
}

@media (max-width: 768px) {
    section.extra-info-section div.text-cont,
    section.evening-section div.text-cont {
        max-width: 100%;
    }
    section.main .text-cont .immerse~p {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }
}


/* Hide some elements when in mobile landscape */

@media (orientation: landscape) and (max-width: 1000px) {
    .text-cont .branding {
        margin: auto;
    }
}

.text-cont .branding {
    /* width: 60%; */
    margin: auto;
}

@media (min-width: 992px) {
    section.main .text-cont {
        max-width: 70%;
    }
    section.extra-info-section div.text-cont {
        max-width: 55%;
    }
}


/* Hide lead text on first slide on mobile */

@media (max-width: 1000px) and (orientation: landscape) {
    .lead-text {
        display: none !important;
    }
    .text-cont .branding {
        position: relative;
        top: 10px;
    }
}

#floating-button .button {
    width: auto !important;
}

#footer-book {
    width: 15em;
}

@media (max-width: 700px) {
    #floating-button .button {
        font-size: 84%;
    }
}


/* end inherited  */


/* Game landing pages top  */

section.main.top {
    padding-bottom: 1rem;
    padding-top: 7rem;
}

@media (orientation: landscape) {
    section.main.top {
        height: 100vh;
    }
}

@media (orientation: portrait) {
    section.main.top {
        height: calc(100vh - 3.11rem);
    }
}

section.main.top .container {
    position: relative;
    z-index: 3;
    justify-content: space-between;
    margin-top: 0;
    height: 100%;
}

section.main.top .text-cont .branding {
    margin: auto;
    margin: 0 auto;
}

section.top .maintitle {
    margin: 2.75rem auto 1.5rem;
}

.maintitle img {
    max-height: 10rem;
    width: auto;
    object-fit: contain;
    max-width: 90vw;
}

section.top .subtitle {
    margin-bottom: 0.75rem;
    margin-top: 0;
    padding: 0 0 0.25rem;
    line-height: 0.9;
}

.landing-page .top .button {
    width: max-content;
    margin: 1rem auto;
}

.landing-page .hero-bottom {
    flex-grow: 1;
    position: relative;
}

#glowing-chevron-scroll {
    visibility: hidden;
}

#learnMoreButtonDetector {
    position: relative;
    bottom: -100vh;
    width: 1px;
    height: 1px;
    display: inline-block;
    visibility: hidden;
}

.booking-not-started:not(.booking-started) #glowing-chevron-scroll {
    visibility: visible;
}

@media screen and (max-width: 600px) {
    .subtitle {
        font-size: 1.4rem;
    }
    section.top .lead-text {
        font-size: 0.85rem;
        width: 100%;
    }
}

body section.top h1:first-of-type {
    padding-top: 0;
    margin-top: 3rem;
    margin-bottom: 1rem;
    font-family: "SolomonBold", "Arial", "Helvetica", "sans-serif";
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: 0.6rem;
    line-height: 1;
    text-shadow: 0 0 14px rgba(235, 222, 204, 0.8);
}

@media screen and (max-width: 365px) {
    body section.top h1:first-of-type {
        letter-spacing: 0.35rem;
    }
}

@media screen and (min-width: 890px) {
    section.main.top .text-cont .branding,
    .hero-bottom {
        flex: 1 1;
    }

    section.main.top + .divider-grad.divider-grad--off-black {
        background: linear-gradient( to bottom, rgba(9, 11, 12, 0) 0%, rgba(9, 11, 12, 0.2) 40%, rgba(9, 11, 12, 1) 48%, rgba(9, 11, 12, 1) 56%, rgba(9, 11, 12, 0) 100%);
    }
}

@media screen and (max-width: 890px) {
    section.main.top {
        padding-bottom: 3.5rem;
        padding-top: 3.5rem;
    }
    section.top .maintitle {
        margin: 0 auto 0.5rem;
    }
    .maintitle img {
        object-fit: contain;
        max-height: 8rem;
    }
    body section.top h1:first-of-type {
        margin-top: 0.5rem;
        margin-bottom: 0.5rem;
    }
}

@media screen and (min-width: 1200px) and (min-height: 812px) {
    section.top .maintitle {
        margin-top: 4rem;
    }
}


/* Alternative landing page titles */

@media (max-width: 1100px) {
    section.intro.intro-corp .main-heading,
    section.intro.intro-lg .main-heading {
        top: 1em;
        position: relative;
    }
}

@media screen and (max-width: 890px) {
    section.intro.intro-corp .main-heading,
    section.intro.intro-lg .main-heading {
        top: 0.5em;
    }
}

@media (max-width: 768px) {
    section.intro.intro-corp .main-heading,
    section.intro.intro-lg .main-heading {
        font-size: 34px;
    }
    section.intro.intro-corp .main-heading {
        top: 1.5em;
    }
}

@media (min-width: 1200px) and (min-height: 812px) {
    section.main.intro-lg .text-cont .branding h1,
    section.intro.intro-corp .hero-top h1.main-heading {
        letter-spacing: 0.2rem;
        font-size: 3.75rem;
    }
}


/* Videos */

.trailer-video.full-width {
    position: fixed;
    z-index: 0;
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    top: 3.1rem;
    top: 0;
}

.trailer-sound {
    position: absolute;
    width: 4rem;
    height: auto;
    background-color: transparent;
    bottom: 2rem;
    right: 1rem;
    z-index: 5;
    opacity: 1;
    transition: opacity 0.5s ease;
}

/* Different settings when inside hummingbird */

#hummingbird_static .trailer-sound, #hummingbird .trailer-sound {

    top: -4rem;
    bottom: unset;

}

.hero-visible-scroll-marker {
    position: relative;
}

[data-scrolledvh] .trailer-sound {
    display: none;
}


.trailer-sound img {
    width: 3rem;
    height: auto;
    display: none;
}

.trailer-sound--mute .mute-icon {
    display: inline-block;
}

.trailer-sound--unmute .unmute-icon {
    display: inline-block;
}


/* Safari on ios15 doesn't like our fixed video so forcing mobiles to not do it  */

@media (max-width: 720px){

    .trailer-video.full-width {position: absolute;}

}

.how-to-play .main:not(.top) .trailer-video.full-width {
    position: absolute;
}

.touchzone.overlay::before {
    background-color: rgba(0, 0, 0, 20%);
    z-index: 1;
}

[data-scrolledvh] .top .trailer-video.full-width {
    opacity: 0;
    display: none;
}

.trailer-video-controls {
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    margin-bottom: 10px;
}

.trailer-video-controls img {
    display: inline-block;
    height: 2.6rem;
    width: auto;
    margin: 0 0.25rem;
    opacity: 0.5;
}

.trailer-video-controls.center-controls {
    bottom: calc(50% - 1.3rem);
    top: calc(50% - 1.3rem);
    left: calc(50% - 4rem);
    right: calc(50% - 4rem);
}

@media screen and (max-width: 890px) {
    .trailer-video-controls {
        bottom: calc(50% - 1.3rem);
        top: calc(50% - 1.3rem);
        left: calc(50% - 4rem);
        right: calc(50% - 4rem);
    }
    .trailer-video-controls .flex {
        justify-content: center;
    }
    .how-to-play section.touchzone:not(.top) .trailer-video-controls {
        bottom: 30%;
        top: 70%;
    }
    .how-to-play section.touchzone:not(.top) .text-cont {
        margin-top: 0;
    }
}

.full-video-link-wrap {
    margin: 0.75rem auto 0;
    text-shadow: 0 0 16px rgba(0, 0, 0, 1);
}

.full-video-link-wrap svg {
    height: 1.75rem;
    width: auto;
    vertical-align: middle;
    display: inline-block;
    margin-right: 0.75rem;
}

.full-video-link-wrap span {
    color: white;
    vertical-align: middle;
}

.full-video-link-wrap a:link,
.full-video-link-wrap a:visited {
    color: white;
}

.full-video-link-wrap:hover a span {
    color: #dd1515;
}

.full-video-link-wrap:hover svg {
    fill: #dd1515;
}

.flavour-text {
    margin-bottom: 0;
    line-height: 1.2;
}

.coming-soon-text {
    padding-top: 1rem;
    margin-bottom: 4rem;
}

#displaced-content {
    position: relative;
    bottom: 0;
}

.glowing-chevron-wrapper {
    width: max-content;
    position: relative;
    cursor: pointer;
}

.glowing-chevron-wrapper .chevron,
.glowing-chevron-wrapper .chevron-glow{
    width: 3rem;
    height: auto; 
    display: block;   
}

.glowing-chevron-wrapper .chevron-glow{
    position: absolute;
    top: 0;
    opacity: 0;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        opacity: 0.1;
    }
    
    50% {
        opacity: 1;
    }
    
    100% {
        opacity: 0;
    }
  }

@media (max-width: 768px) {
    section.intro .flavour-text {
        font-size: 1.25rem;
        line-height: 1.1;
    }
}

section.main .text-cont .divider::after {
    content: "";
    width: 140px;
    height: 1px;
    background: #73c7b5;
    transform: rotate(8deg);
    display: block;
    margin: 0 auto;
}

section.main .text-cont .divider.left::after {
    content: "";
    width: 140px;
    height: 1px;
    background: #73c7b5;
    transform: rotate(-8deg);
    display: block;
    margin: 0 auto;
}

@media (min-width: 768px) {
    section.main .text-cont .branding h1 {
        font-size: 3rem;
    }
    section.main .text-cont .divider::after {
        width: 190px;
        margin: 1.6rem auto 2rem;
    }
    section.main .text-cont .divider.left::after {
        width: 190px;
        margin: 1.6rem auto 2rem;
    }
}

@media (min-width: 576px) {
    section.main .text-cont .divider::after {
        width: 180px;
        margin: 2rem auto 2.2rem;
    }
    section.main .text-cont .divider.left::after {
        width: 180px;
        margin: 2rem auto 2.2rem;
    }
}

@media (min-width: 1200px) and (min-height: 812px) {
    .landing-page section.main .text-cont h1 {
        font-size: 4.5rem;
        letter-spacing: 1.2rem;
    }
    section.main .text-cont .divider::after {
        width: 190px;
        margin: 3.5rem auto 3.2rem;
    }
    section.main .text-cont .divider.left::after {
        width: 190px;
        margin: 3.5rem auto 3.2rem;
    }
}


/* enchanted-mirror.css OVERRIDES */

section.main .text-cont .divider.divider-custom {
    display: block;
    margin: 1rem 0;
}

section.main .text-cont .divider.divider-custom::after {
    display: none;
}

@media (min-width: 576px) {
    section.main .text-cont .divider.divider-custom {
        display: block;
        margin: 1.5rem 0;
    }
    section.main .text-cont .divider.divider-custom>img {
        max-width: 180px;
    }
}

section.main .text-cont .divider.divider-custom>img {
    width: 100%;
    height: auto;
    max-width: 120px;
}

@media screen and (max-width: 600px) {
    section.main.top .text-cont {
        height: 100%;
        padding: 0;
        max-width: calc(100% - 2rem);
        margin: 0 auto;
    }
    .text-cont .branding {
        margin-top: 0;
    }
}

section.main:not(.top) .text-cont {
    margin-top: 6rem;
    margin-bottom: 6rem;
}

section.main .narrow {
    max-width: 250px;
}

.variable-size-text,
section.main .variable-size-text {
    font-size: 0.8rem;
    font-size: 1.2rem;
    letter-spacing: normal;
    line-height: 1.4;
}

.variable-size-text p {
    font-size: inherit;
}

.variable-size-text strong {
    font-size: 1.2rem;
}

section.main .text-cont .stars {
    white-space: normal;
}

.heading.immerse {
    font-family: "IntroBold", "Arial", "Helvetica", "sans-serif";
    text-transform: uppercase;
    line-height: 1.1;
    margin-top: 2.5em;
}

.heading.immerse:first-child {
    margin-top: 0;
}

section.main .text-cont .immerse~p {
    font-family: "RajdhaniRegular", "Arial", "Helvetica", "sans-serif";
    text-transform: unset;
    margin: 1em auto 0.5em;
    font-size: 1.2rem;
    letter-spacing: 0.01em;
}

section.main .text-cont .extra-info-wrap {
    margin-bottom: 3.5rem;
}

section.fast-book-head {
    margin-top: 6rem;
}

section.main.faqs {
    background-color: rgba(9, 11, 12, 0.6);
}

#reviews q {
    font-family: "RajdhaniSemiBold", "Arial", "Helvetica", "sans-serif";
}

.booking-outline {
    margin-left: auto;
    margin-right: auto;
}

@media screen and (max-width: 890px) {
    .booking-outline {
        margin-top: 2rem;
        margin-bottom: 6rem;
    }
}


.hero-bottom.justify-end .flavour-text {
    max-width: 600px;
}


/* v2.css OVERRIDES */

@media screen and (max-width: 890px) {
    .booking-outline {
        margin-left: 0;
        margin-right: 0;
    }
}


/* moriartys-game.css OVERRIDES */

section.main .text-cont .extra-info-wrap {
    margin-bottom: 0;
}


/* End old stylesheet sheet overrides */

.faqs h2.gamesmaster {
    background-image: url("../images/headers/faqs.jpg");
    background-size: cover;
    margin: 0;
    padding-top: 2.75em;
    padding-bottom: 0.5em;
    font-size: 50px;
    text-shadow: 0 0 1em white;
    max-width: 100%;
    background-position: center;
}

.faqs .header-description {
    margin: 1em 1em 0 1em;
}

.faqs .questions-block {
    margin-top: 0;
    padding-top: 2em;
}

@media screen and (max-width: 890px) {
    .faqs h2.gamesmaster {
        padding-left: unset;
        padding-right: unset;
    }
}

@media screen and (max-width: 430px) {
    .faqs h2.gamesmaster {
        font-size: 45px;
    }
}


/* Hide hero image if has video */

[data-has-video] section.main.top {
    background-size: 0 0;
    position: relative;
}


/* Fancybox lighbox styles */

.fancybox-video,
.fancybox-video:focus {
    outline: none;
}

.full-video-link-wrap:focus,
.full-video-link-wrap *:focus {
    outline: none;
}

@media (max-width: 890px) {
    .fancybox-toolbar {
        top: 3em !important;
    }
}


/* iphone corrections */

@media screen and (max-width: 890px) {
    [data-ios] section.main.top {
        padding-bottom: 9rem;
    }
}


/* landing_probation.css from b test 31-08-2021 */

.video-image-bullets-explainer .video .heading {
    margin-bottom: 0.5em;
}

.video-image-bullets-explainer .video-image-bullets-explainer-bullets {
    align-self: center;
}

.video-image-bullets-explainer .video-image-bullets-explainer-bullets ul {
    margin-top: 0;
}

.video-image-bullets-explainer .video-image-bullets-explainer-bullets ul:last-child,
.video-image-bullets-explainer .video-image-bullets-explainer-bullets ul:last-child li:last-child,
.video-image-bullets-explainer .more-about-event .more-about-how-it-works p {
    margin-bottom: 0;
}

.video-large-centre {
    width: 100%;
    margin: auto;
}


/* what_you_get.css from b test 31-08-2021 */

.divider-grad.divider-grad--off-black {
    background: -moz-linear-gradient( top, rgba(9, 11, 12, 0) 0%, rgba(9, 11, 12, 1) 46%, rgba(9, 11, 12, 1) 56%, rgba(9, 11, 12, 0) 100%);
    /* FF3.6-15 */
    background: -webkit-linear-gradient( top, rgba(9, 11, 12, 0) 0%, rgba(9, 11, 12, 1) 46%, rgba(9, 11, 12, 1) 56%, rgba(9, 11, 12, 0) 100%);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient( to bottom, rgba(9, 11, 12, 0) 0%, rgba(9, 11, 12, 1) 46%, rgba(9, 11, 12, 1) 56%, rgba(9, 11, 12, 0) 100%);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

section.landing-page>section.black-base:not(.image-background) {
    z-index: 2;
}

section.landing-page section.image-background {
    background-size: cover;
    background-position: center;
}

section.landing-page>section.image-background {
    z-index: 1;
}

.reviews-block.vertical-divider-padding {
    padding-bottom: 4rem;
}










/* What you get block */

.pre-what-you-get-player-price {
  padding-left: 3.5rem;
}






/* Social links block */

.gather-social-links {
    justify-content: space-around;
}

.gather-social-links svg {
    width: 3rem;
    height: 3rem;
}

@media screen and (min-width: 360px) {
    .gather-social-links {
        justify-content: center;
        gap: 1.5rem;
    }
    .gather-social-links svg {
        width: 3.5rem;
        height: 3.5rem;
    }
}

@media screen and (min-width: 520px) {

    .gather-social-links {
        gap: 2.5rem;
    }

    .gather-social-links svg {
        width: 5rem;
        height: 5rem;
    }
}



@media screen and (min-width: 780px) {

    .gather-social-links {
        gap: 4rem;
    }

    .gather-social-links svg {
        width: 8rem;
        height: 8rem;
    }
}


/* Slideshow */

.hc-slideshow {
    position:relative;
}

.hc-slideshow img.slideshow-img {
    transition: all 500ms;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

@media screen and (max-width: 576px) {
    .hc-slideshow .text-image-block--blended__stacked-img-wrapper--rl {
        box-shadow: unset;
    }
    .hc-slideshow .text-image-block--blended__stacked-img-wrapper--rl + .the-experience__inner {
        padding-top: 2.5rem;
    }
}

/* 007 logo */

.the-experience__heading {
    margin-bottom: 1rem;
}

.the-experience__heading .heading {
    vertical-align: baseline;
}

.inline-007-logo {
    height: 0.76em;
    vertical-align: baseline;
}

@media (max-width: 600px) {
    .inline-007-logo {
        margin-top: 0.75rem;
        height: 1em;
    }
}


/* Experience more */

#experience-more-button {
    position: relative;
    display: inline-block;
}

#experience-more-button::before {
    content: "";
    background: linear-gradient(90deg, rgba(9,11,12,0.1) 20%, rgba(9,11,12,1) 75%, rgba(9,11,12,0.1) 99%);
    width: 58px;
    height: 25px;
    position: absolute;
    left: -50px;
    z-index: 0;
    box-shadow: -1px 0px 13px 5px rgba(0, 0, 0, 0.32);
}

#experience-more-button b {
    position: relative;
    z-index: 1;
}

#experience-more {
    width: 55rem;
    max-width: calc(100% - 2rem);
}

.experience-more-inner {
    position: relative;
    top: -50px;
    width: 450px;
    max-width: 100%;
}

@media screen and (min-width: 578px) {
    #experience-more {
        max-width: 100%;
    }
}

@media screen and (min-width: 890px) {
    #experience-more {
        max-width: calc(100% - 4rem);
    }
    .experience-more-inner {
        width: 520px;
    }
}

@media screen and (min-width: 1024px) {
    #experience-more {
        max-width: calc(100% - 7rem);
    }
    .experience-more-inner {
        width: 28rem;
    }
}
  
  

@media (max-width: 500px){

    [data-route="landing-007"] {

        .book-with-confidence-icon-wrapper {
            flex: 0;
        }

        .book-with-confidence-icon {
            width: 3.6rem;
            height: 3.6rem;
            margin-right: 1.5rem;
            margin-left: 1rem;
        }
    }
}
